import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { FacebookLoginService, RESERVATION_REQUEST, type EnrollmentData, type ReservationApiResponse } from '@sevenrooms/core/api'
import {
  CreditCardPaymentRuleEnum,
  type CountryCode,
  type ReservationRequest,
  type CheckoutData,
  type RedemptionData,
} from '@sevenrooms/core/domain'
import { ReservationWidget } from '@sevenrooms/core/domain/constants'
import { type Field, useWatchMany } from '@sevenrooms/core/form'
import { useLocales } from '@sevenrooms/core/locales'
import { useNavigation } from '@sevenrooms/core/navigation'
import { routes } from '@sevenrooms/core/routes'
import { DateOnly, DateTime } from '@sevenrooms/core/timepiece'
import { EmbeddedMap, type GoogleJWT, type ReCAPTCHARef } from '@sevenrooms/core/ui-kit/core'
import { ErrorBoundary } from '@sevenrooms/core/ui-kit/core/ErrorBoundary'
import { Form, FormReCaptcha, Label, SubmitButton } from '@sevenrooms/core/ui-kit/form'
import { removeBeforeUnloadListener } from '@sevenrooms/core/ui-kit/hooks'
import { Box, VStack, CardSection } from '@sevenrooms/core/ui-kit/layout'
import type { CamelCaseKeys } from '@sevenrooms/core/utils'
import {
  AccountTypes,
  type PaymentCardForm,
  FormGMapsAddressInput,
  PaymentFlows,
  CheckoutProvider,
  usePaymentsContext,
} from '@sevenrooms/payments'
import {
  AgreementTerms,
  BirthdayForm,
  BookingPolicy,
  type OrderAgreementTermSingleProps,
  PostalCodeForm,
  PreferredLanguageForm,
  PromoCodeCard,
  RedemptionGiftCard,
  ReservationBreadcrumb,
  ReservationCheckoutGuestLayout,
  ReservationCheckoutOrderSummary,
  ReservationDetailCard,
  WidgetPortal,
  PrivateEventsSummary,
} from '../../components'
import {
  useCachedCreateReservationHold,
  useClientLogin,
  useModifyReservationRoute,
  useReservationNavigation,
  useReservationsRoute,
  useVenue,
  useWidgetLanguage,
  useWidgetSettings,
  useAnalyticsSettings,
  useClientTokenData,
  useVenueGroup,
} from '../../hooks'
import { reservationWidgetMessages } from '../../reservationWidgetMessages'
import {
  type UpdateFormStateCheckout,
  useCreateReservationMutation,
  useEditReservationMutation,
  useGetGuestFacingUpgradeQuery,
  useModals,
  useReservationFormState,
} from '../../store'
import {
  failedCheckout,
  finishCheckoutPage,
  loadCheckoutPage,
  loginWithSocial,
  successfulCheckout,
  trackTealium,
  transformUpgradesDataToCheckout,
} from '../../utils'
import { CheckoutContainer } from './CheckoutContainer'
import { GuestLoginPanel } from './GuestLoginPanel'
import { useCheckoutForm } from './useCheckoutForm'
import { useCheckoutTotals } from './useCheckoutTotals'
import { UserContactDetails } from './UserContactDetails'

export function Checkout() {
  const { useSubmitPayment, PaymentCheckoutComponent, onBookingAttemptStatusChange, paymentFlow } = usePaymentsContext()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const { push } = useNavigation()
  const { primaryVenueId, requestId } = useReservationsRoute()
  const { formatMessage } = useLocales()
  const { showErrorModal, hideModal } = useModals()
  const {
    venueLanguages,
    id: venueId,
    countryCode: venueCountryCode,
    urlKey: venueUrlKey,
    name: venueName,
    gmapsEmbedUrl,
    gmapsLinkByPlaceId,
    currencyCode,
    paymentType,
    isSmsMarketingEnabled,
    includeEndTime,
    redemptionSystemsEnabled,
  } = useVenue()
  const widgetSettings = useWidgetSettings()
  const analyticsSettings = useAnalyticsSettings()
  const { id: clientToken } = useClientTokenData()
  const { lockedFields } = useVenueGroup()
  const showGiftCard = widgetSettings.enableGiftCardRedemption && redemptionSystemsEnabled.includes('VALUTEC')
  const {
    formState: {
      partySize: timeSlotPartySize,
      privateEventsPartySize,
      selectedTimeSlot,
      selectedExperience,
      categories,
      agreedCustomCheckoutPolicy,
      agreedToAboveAgeConsentOn,
      agreedToBookingPolicy,
      agreedToReservationSmsOptIn,
      agreedToVenueGroupMarketingOptIn,
      agreedToVenueSpecificMarketingOptIn,
      agreedToVenueSmsMarketingOptIn,
      agreedToGroupBookingsPolicy,
      birthdayDay,
      birthdayMonth,
      emailAddress,
      firstName,
      lastName,
      gratuityPercentage,
      phoneNumber,
      phoneCountryCode,
      postalCode,
      preferredLanguage,
      trackingSlug,
      actual,
      clientId,
      promoCode,
      salutation,
    },
    updateFormState,
    clearFormState,
  } = useReservationFormState()
  const partySize = selectedExperience && privateEventsPartySize !== null ? privateEventsPartySize : timeSlotPartySize
  const showGroupBookingPolicy = !!selectedExperience

  useEffect(() => {
    // Send the Google Analytics event for checkout page load
    loadCheckoutPage(venueUrlKey, analyticsSettings.analyticsEventMapping.checkoutStarted)
  }, [analyticsSettings.analyticsEventMapping.checkoutStarted, venueUrlKey])

  const languageOptions = venueLanguages.map(language => ({ id: language.value, label: language.name }))
  const { birthdayType, postalCodeType } = widgetSettings
  const recaptchaRef = useRef<ReCAPTCHARef>(null)
  const { data: upgradesData } = useGetGuestFacingUpgradeQuery(venueId)
  const [createReservation] = useCreateReservationMutation()
  const [editReservation] = useEditReservationMutation()
  const {
    baseAmount,
    dueNowAmount,
    gratuityAmount,
    promoCodeDiscountAmount,
    promoCodeName,
    serviceChargeAmount,
    subtotalAmount,
    taxAmount,
    totalAmount,
    upgradesAmounts,
    upsellAmount,
    hasSelectableGratuity,
    selectedGratuityAmount,
    giftCardDiscountAmount,
  } = useCheckoutTotals()

  const saveToLocalStorage = useCallback((value: string, key: string) => {
    window.localStorage.setItem(key, JSON.stringify(value))
  }, [])

  // time slot base amount + upsell amount
  const hasSubtotal = subtotalAmount > 0

  const onGratuityPercentageChange = useCallback(
    (value: number | null) => {
      updateFormState({ gratuityPercentage: value })
    },
    [updateFormState]
  )
  const { selectedLanguage, availableLanguageOptions } = useWidgetLanguage()
  const showLangPicker = availableLanguageOptions.length > 1
  const [, { data: reservationHoldData }] = useCachedCreateReservationHold()

  const onPaymentComponentError = useCallback(
    (error: Error) => {
      // eslint-disable-next-line no-console
      console.error(error?.message || 'Payment init error')
      showErrorModal()
    },
    [showErrorModal]
  )

  const submitPaymentMutation = useSubmitPayment({ venueId, venueUrlKey })

  const isPaymentRequired = useMemo(() => {
    if (!selectedTimeSlot || !partySize) {
      return false
    }
    if (dueNowAmount) {
      return true
    }
    // Modify reservation flow, card was saved before
    const hasCreditCardOnFile = !!actual?.paymentsBillingProfile

    const { requireCreditCard, ccPaymentRule, ccPartySizeMin } = selectedTimeSlot
    // if there is no balance, but PaymentRule is SAVE_FOR_LATER, ask for cc if min party size is met
    return !!(
      requireCreditCard &&
      !hasCreditCardOnFile &&
      ccPaymentRule === CreditCardPaymentRuleEnum.SAVE_FOR_LATER &&
      (!ccPartySizeMin || partySize > ccPartySizeMin)
    )
  }, [selectedTimeSlot, partySize, dueNowAmount, actual?.paymentsBillingProfile])
  const shouldShowBillingAddress = paymentType === AccountTypes.FREEDOMPAY && isPaymentRequired
  const shouldShowCustomPaymentForm = paymentType === AccountTypes.CYBERSOURCE_3DS_REDUX && isPaymentRequired

  const handleOnInvalid = useCallback(data => {
    // eslint-disable-next-line no-console
    console.log(data)
  }, [])

  const form = useCheckoutForm({
    agreedCustomCheckoutPolicy,
    agreedToAboveAgeConsentOn,
    agreedToBookingPolicy,
    agreedToReservationSmsOptIn,
    agreedToVenueGroupMarketingOptIn,
    agreedToVenueSpecificMarketingOptIn,
    agreedToVenueSmsMarketingOptIn,
    agreedToGroupBookingsPolicy,
    birthdayDay,
    birthdayMonth,
    birthdayType: widgetSettings.birthdayType,
    emailAddress,
    firstName,
    lastName,
    gratuityPercentage,
    hasReCaptcha: widgetSettings.recaptchaOn,
    phoneNumber,
    phoneCountryCode: phoneCountryCode || venueCountryCode,
    postalCode,
    postalCodeType: widgetSettings.postalCodeType,
    preferredLanguage: selectedLanguage || preferredLanguage,
    hasAgeToConsent: widgetSettings.aboveAgeConsentOn,
    hasCustomCheckoutPolicy: widgetSettings.customCheckoutPolicyOn,
    hasRequiredClientSelectableGratuity: hasSelectableGratuity && isPaymentRequired,
    hasBookingPolicy: isPaymentRequired,
    hasBillingAddress: shouldShowBillingAddress,
    hasCustomPaymentForm: shouldShowCustomPaymentForm,
    hasGroupBookingsPolicy: showGroupBookingPolicy,
    clientId,
    promoCode,
    salutation,
    salutationType: widgetSettings.salutationType,
  })

  const { field, setValue, getValues } = form

  const [agreedToVenueSmsMarketingOptInRT, agreedToReservationSmsOptInRT, billingAddress] = useWatchMany(field, [
    'agreedToVenueSmsMarketingOptIn',
    'agreedToReservationSmsOptIn',
    'billingAddress',
  ])

  const { isModifyRoute } = useModifyReservationRoute()
  const { instantExperiencesEnabled } = useReservationsRoute()

  const onRedemptionFieldChange = useCallback(
    (redemptionData: RedemptionData | undefined) => {
      updateFormState({ redemptionData })
    },
    [updateFormState]
  )

  useEffect(() => {
    if (billingAddress?.postalCode) {
      setValue('postalCode', billingAddress.postalCode, { shouldValidate: true })
    }
  }, [billingAddress, setValue])

  const onNavigatePrevious = useCallback(() => {
    updateFormState(getValues())
  }, [updateFormState, getValues])
  const { navigatePrevious, navigateToFirstStep } = useReservationNavigation({ onNavigatePrevious })

  const onClientLogin = useCallback(
    ({
      firstName,
      lastName,
      emailAddress,
      phoneCountryCode,
      phoneDialCode,
      phoneNumber,
    }: {
      firstName?: string
      lastName?: string
      emailAddress?: string
      phoneNumber?: string
      phoneCountryCode?: CountryCode
      phoneDialCode?: string
    }) => {
      if (firstName) {
        setValue('firstName', firstName, { shouldValidate: true })
      }
      if (lastName) {
        setValue('lastName', lastName, { shouldValidate: true })
      }
      if (emailAddress) {
        setValue('emailAddress', emailAddress, { shouldValidate: true })
      }
      if (phoneCountryCode && phoneDialCode && phoneNumber) {
        setValue('phoneNumber', phoneNumber, { shouldValidate: true })
        setValue('phoneDialCode', phoneDialCode)
        setValue('phoneCountryCode', phoneCountryCode)
      }
    },
    [setValue]
  )

  const submitBtnLabel = useMemo(() => {
    if (paymentFlow === PaymentFlows.REVERSED && isPaymentRequired) {
      return formatMessage(reservationWidgetMessages.proceedToPaymentLabel)
    }
    return isModifyRoute
      ? formatMessage(reservationWidgetMessages.resWidgetCheckoutModifyButtonLabel)
      : formatMessage(reservationWidgetMessages.resWidgetCheckoutSubmitButtonLabel)
  }, [formatMessage, isModifyRoute, isPaymentRequired, paymentFlow])

  const {
    provider,
    setClientLoginDetails,
    userInfo: { clientId: sevenroomsClientId },
  } = useClientLogin(onClientLogin)

  const handleOnSubmit = async (data: UpdateFormStateCheckout) => {
    setIsSubmitting(true)
    if (onBookingAttemptStatusChange) {
      onBookingAttemptStatusChange(false)
    }
    updateFormState(data)
    if (!selectedTimeSlot) {
      return
    }
    const upgrades = transformUpgradesDataToCheckout(categories, upgradesData?.inventories)
    const venueGroupMarketingOptIn = widgetSettings.venueGroupMarketingOn ? data.agreedToVenueGroupMarketingOptIn : undefined
    const venueSpecificMarketingOptIn = widgetSettings.venueSpecificMarketingOn ? data.agreedToVenueSpecificMarketingOptIn : undefined
    const venueSmsMarketingOptIn = widgetSettings.venueSmsMarketingOn ? data.agreedToVenueSmsMarketingOptIn : undefined
    let { billingAddress } = data
    if (shouldShowCustomPaymentForm) {
      billingAddress = data?.payment?.billingAddress
    }

    const submitData = {
      userData: {
        globalClientId: sevenroomsClientId,
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.emailAddress,
        phoneNumber: data.phoneNumber,
        phoneCountryCode: data.phoneCountryCode,
        phoneDialCode: data.phoneDialCode,
        birthdayDay: data.birthdayDay,
        birthdayMonth: data.birthdayMonth,
        preferredLanguageCode: data.preferredLanguage,
        postalCode: data.postalCode,
        socialMediaPicture: data.socialMediaPicture,
        socialMediaUserId: data.socialMediaUserId || undefined,
        socialMediaLoginSite: data.socialMediaLoginSite,
        socialMediaLocation: data.socialMediaLocation,
        // agreement to check out fields
        venueGroupMarketingOptIn,
        venueSpecificMarketingOptIn,
        venueSmsMarketingOptIn,
        marketingOptIn: venueGroupMarketingOptIn || venueSpecificMarketingOptIn,
        displayReservationSmsOptIn: widgetSettings.displayReservationSmsOptIn,
        reservationSmsOptIn: widgetSettings.displayReservationSmsOptIn ? data.agreedToReservationSmsOptIn : undefined,
        agreedToAboveAgeConsent: widgetSettings.aboveAgeConsentOn ? data.agreedToAboveAgeConsentOn : undefined,
        agreedToGroupBookingsPolicy: showGroupBookingPolicy ? data.agreedToGroupBookingsPolicy : undefined,
        agreedCustomCheckoutPolicy: widgetSettings.customCheckoutPolicyOn ? data.agreedCustomCheckoutPolicy : undefined,
        // We don't select tags on ui, that's why always true
        agreedToGdprDietaryOptin: widgetSettings.requireDietaryTagGdprOptIn ? true : undefined,
        salutation: data.salutation ?? undefined,
        clientRequest: undefined, // note field of request modal, for now empty
        trackingSlug,
      },
      reservationData: {
        shiftPersistentId: selectedTimeSlot.shiftPersistentId ?? '',
        accessPersistentId: selectedTimeSlot.accessPersistentId ?? '',
        date: selectedTimeSlot?.timeIso,
        time: selectedTimeSlot.time,
        partySize,
        venueId, // Need to change in the future for group widget to "state.venueEntities[timeSlotVenue].id"
        selectedUpsells: upgrades,
        reservationHoldId: reservationHoldData?.reservationHoldId,
        promoCodeId: data.promoCode?.id || undefined,
        recaptcha: data.reCaptcha || undefined,
        pickedDuration: undefined, // we don't support duration picker for the new widget
        campaign: undefined, // in old widget this value taken from url "state.app.requestParams.campaign"
        clientToken,
        widgetPrimaryVenueId: primaryVenueId || venueId,
        processId: undefined, // undefined for stripe payment provider
        requestId,
        actualId: actual?.id,
      },
      chargeData: {
        chargeBaseAmount: baseAmount,
        chargeUpsellAmount: upsellAmount,
        promoDiscountAmount: promoCodeDiscountAmount,
        selectedGratuityRate: data.gratuityPercentage ?? 0,
        chargeGratuity: gratuityAmount,
        chargeServiceCharge: serviceChargeAmount,
        chargeTax: taxAmount,
        chargeAmount: totalAmount,
        amountDue: dueNowAmount,
        gratuityRate: undefined, // this field not used in current widget as well
        redemptionData: data.redemptionData,
        redemptionEncryptionId: widgetSettings.redemptionCardEncryptionId,
        redemptionSystem: 'VALUTEC',
        // these fields are empty now, because used with payment providers haven't supported yet by new widget
        cardData: undefined, // new CyberEncrypt(), used for not stripe payment providers
        zip: undefined,
        address1: billingAddress?.address1,
        city: billingAddress?.city,
        locality: billingAddress?.locality,
        administrativeArea: billingAddress?.administrativeArea,
        countryCode: billingAddress?.countryCode,
        browserScreenWidth: window.innerWidth.toString(),
        browserScreenHeight: window.innerHeight.toString(),
      },
      // eslint-disable-next-line no-warning-comments
      // TODO GX-2914
      clientData: {
        trackingSlug,
        clientId,
        channel: 'SEVENROOMS_WIDGET',
        experienceId: selectedExperience?.id,
        referralId: undefined,
        newWidgetUsed: true,
      },
      // these fields are empty now, because we don't have tags selection on UI of new widget
      tagsData: {
        clientTags: [],
        reservationTags: [],
      },
    }

    const submitBookReservation = (async (submitData: ReservationRequest) =>
      isModifyRoute ? editReservation(submitData).unwrap() : createReservation(submitData).unwrap()) as (
      submitData: ReservationRequest
    ) => Promise<CamelCaseKeys<ReservationApiResponse> | undefined>

    const onSuccessfulBooking = (bookingResponse: CamelCaseKeys<ReservationApiResponse> | undefined) => {
      if (!bookingResponse) {
        return
      }

      const { token } = bookingResponse
      if (data.preferredLanguage) {
        saveToLocalStorage(data.preferredLanguage, ReservationWidget.PreferredLanguageCookie)
      }
      successfulCheckout(venueUrlKey, token, analyticsSettings.analyticsEventMapping.checkoutSuccessful, {
        partySize,
        value: totalAmount,
        currency: currencyCode,
        merchant_id: venueId,
        reservation_date: selectedTimeSlot?.timeIso,
        reservation_time: actual?.arrivalTime,
        email: data.emailAddress,
      })
      finishCheckoutPage(venueUrlKey)
      trackTealium(
        venueUrlKey,
        venueName,
        phoneCountryCode || venueCountryCode,
        DateOnly.fromSafe(selectedTimeSlot?.timeIso)?.formatFMonthNDaySWeek(),
        DateTime.fromSafe(selectedTimeSlot?.timeIso)?.toTimeOnly().toHoursMinutesIso(),
        partySize
      )
      push(routes.explore.reservations.manage, {
        params: { venueKey: venueUrlKey },
        query: {
          token,
          lang: selectedLanguage,
          is_success: isModifyRoute ? undefined : 'true',
          is_success_edit: isModifyRoute ? 'true' : undefined,
        },
      })
      clearFormState()
      recaptchaRef?.current?.reset()
      hideModal()
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const onFailure = (error?: any, options?: { keepLoading?: boolean; suppressTracking?: boolean }) => {
      recaptchaRef?.current?.reset()
      if (paymentType === AccountTypes.STRIPE && error?.type === 'validation_error') {
        return
      }
      // eslint-disable-next-line no-console
      console.log(error)
      const errorTitle = error?.errors?.[0] ?? formatMessage(reservationWidgetMessages.commonPaymentErrorPaymentRejectedError)
      const errorMessage = error?.errors?.[0] ? undefined : formatMessage(reservationWidgetMessages.resPaymentFailedModalHeader)
      showErrorModal(errorTitle, errorMessage)
      if (options?.suppressTracking) {
        failedCheckout(venueUrlKey, errorMessage ?? 'Checkout Failed')
      }
      if (error?.origin === RESERVATION_REQUEST) {
        if (onBookingAttemptStatusChange) {
          onBookingAttemptStatusChange(true)
        }
      }
      if (!options?.keepLoading) {
        setIsSubmitting(false)
      }
    }
    setIsSubmitting(true)
    try {
      if (!isPaymentRequired) {
        const response = await submitBookReservation(submitData as ReservationRequest)
        onSuccessfulBooking(response)
      } else if (paymentFlow === PaymentFlows.DIRECT) {
        let chargeData = {}
        if (submitPaymentMutation) {
          chargeData = await submitPaymentMutation({ amountDue: dueNowAmount, data: submitData })
        }
        submitData.chargeData = { ...submitData.chargeData, ...chargeData }

        const response = await submitBookReservation(submitData as ReservationRequest)
        onSuccessfulBooking(response)
      } else if (paymentFlow === PaymentFlows.REVERSED) {
        await submitPaymentMutation({
          amountDue: dueNowAmount,
          data: submitData as CheckoutData,
          submitBookReservation: submitBookReservation as unknown as (
            submitData: CheckoutData
          ) => Promise<CamelCaseKeys<ReservationApiResponse> | undefined>,
          onSuccessfulBooking: onSuccessfulBooking as unknown as (
            bookingResponse: { token: string; enrollmentResult?: EnrollmentData } | undefined
          ) => void,
          onNavigateOutside: () => {
            removeBeforeUnloadListener()
          },
          onFailure,
          getValues,
        })
      }

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      onFailure(error)
    } finally {
      if (paymentFlow === PaymentFlows.DIRECT) {
        setIsSubmitting(false)
      }
    }
  }

  const onLoginFB = useCallback(async () => {
    const data = await FacebookLoginService.login()
    setClientLoginDetails('facebook', { firstName: data.firstName, lastName: data.lastName, emailAddress: data.email })
    loginWithSocial(venueUrlKey, 'facebook')
    if (data.location?.name) {
      setValue('socialMediaLocation', data.location.name)
    }
    setValue('socialMediaUserId', data.userId)
    setValue('socialMediaPicture', data.picture)
  }, [setClientLoginDetails, venueUrlKey, setValue])

  const onLoginGoogle = useCallback(
    (data: GoogleJWT) => {
      setClientLoginDetails('google', { firstName: data.givenName, lastName: data.familyName, emailAddress: data.email })
      setValue('socialMediaUserId', widgetSettings.googleApiClientId)
      setValue('socialMediaPicture', data.picture)
      loginWithSocial(venueUrlKey, 'google')
    },
    [setClientLoginDetails, setValue, widgetSettings.googleApiClientId, venueUrlKey]
  )

  const agreementTerms = useMemo(() => {
    const agreements: OrderAgreementTermSingleProps[] = []
    if (showGroupBookingPolicy) {
      agreements.push({
        field: field.prop('agreedToGroupBookingsPolicy'),
        term: `${formatMessage(reservationWidgetMessages.resWidgetGroupBookingsOptInLabel)}*`,
        dataTest: 'sr-agreed-to-group-bookings-policy',
      })
    }

    if (isPaymentRequired) {
      agreements.push({
        field: field.prop('agreedToBookingPolicy'),
        term: `${formatMessage(reservationWidgetMessages.resWidgetCancelPolicyOptInLabel)}*`,
        infoTitle: formatMessage(reservationWidgetMessages.resWidgetCancellationPolicyHeader),
        infoContent: selectedTimeSlot?.cancellationPolicy,
        dataTest: 'sr-agreed-to-booking-policy',
      })
    }

    if (widgetSettings.customCheckoutPolicyOn) {
      agreements.push({
        field: field.prop('agreedCustomCheckoutPolicy'),
        term: `${formatMessage(reservationWidgetMessages.resWidgetCustomPolicyOptInLabel)}*`,
        infoTitle: formatMessage(reservationWidgetMessages.resWidgetCustomPolicyHeader),
        infoContent: formatMessage(reservationWidgetMessages.policyCustomCheckout),
        dataTest: 'sr-agreed-to-custom-checkout-policy',
      })
    }

    if (widgetSettings.aboveAgeConsentOn) {
      agreements.push({
        field: field.prop('agreedToAboveAgeConsentOn'),
        term: `${formatMessage(reservationWidgetMessages.resWidgetAgeConsentLabel)} ${widgetSettings.ageToConsent}*`,
        dataTest: 'sr-agreed-to-above-age-consent-on',
      })
    }

    if (widgetSettings.venueSmsMarketingOn && isSmsMarketingEnabled) {
      agreements.push({
        field: field.prop('agreedToVenueSmsMarketingOptIn'),
        term: formatMessage(reservationWidgetMessages.resWidgetCheckoutVenueSmsOptIn, { venue: venueName }, { ignoreTag: true }),
        infoTitle: formatMessage(reservationWidgetMessages.resWidgetMarketingPolicyHeader),
        infoContent: formatMessage(reservationWidgetMessages.policyVenueSpecificSmsMarketing, { venue: venueName }, { ignoreTag: true }),
        dataTest: 'sr-agreed-to-venue-sms-marketing-opt-in',
      })
    }

    if (widgetSettings.venueSpecificMarketingOn) {
      agreements.push({
        field: field.prop('agreedToVenueSpecificMarketingOptIn'),
        term: formatMessage(reservationWidgetMessages.resWidgetCheckoutVenueOptIn, { venue: venueName }, { ignoreTag: true }),
        infoTitle: formatMessage(reservationWidgetMessages.resWidgetMarketingPolicyHeader),
        infoContent: formatMessage(reservationWidgetMessages.policyVenueSpecificMarketing, { venue: venueName }, { ignoreTag: true }),
        dataTest: 'sr-agreed-to-venue-specific-marketing-on',
      })
    }

    if (widgetSettings.venueGroupMarketingOn) {
      agreements.push({
        field: field.prop('agreedToVenueGroupMarketingOptIn'),
        term: formatMessage(reservationWidgetMessages.resWidgetCheckoutVenueGroupOptIn, { venue: venueName }, { ignoreTag: true }),
        infoTitle: formatMessage(reservationWidgetMessages.resWidgetMarketingPolicyHeader),
        infoContent: formatMessage(reservationWidgetMessages.policyVenueGroupMarketing, { venue: venueName }, { ignoreTag: true }),
        dataTest: 'sr-agreed-to-venue-group-marketing-opt-in',
      })
    }

    if (widgetSettings.displayReservationSmsOptIn) {
      agreements.push({
        field: field.prop('agreedToReservationSmsOptIn'),
        term: formatMessage(reservationWidgetMessages.policyUsTwilioSmsOptIn),
        dataTest: 'sr-agreed-to-reservation-sms-opt-in',
      })
    }

    return agreements
  }, [
    showGroupBookingPolicy,
    isPaymentRequired,
    widgetSettings.customCheckoutPolicyOn,
    widgetSettings.aboveAgeConsentOn,
    widgetSettings.venueSmsMarketingOn,
    widgetSettings.venueSpecificMarketingOn,
    widgetSettings.venueGroupMarketingOn,
    widgetSettings.displayReservationSmsOptIn,
    widgetSettings.ageToConsent,
    isSmsMarketingEnabled,
    field,
    formatMessage,
    selectedTimeSlot?.cancellationPolicy,
    venueName,
  ])

  const onMapClick = useMemo(() => {
    if (!gmapsLinkByPlaceId) {
      // we return undefined here, so that the EmbeddedMap component doesn't render the click wrapper
      return undefined
    }
    const clickFunc = () => {
      window.open(gmapsLinkByPlaceId, '_blank')
    }
    return clickFunc
  }, [gmapsLinkByPlaceId])

  if (!selectedTimeSlot) {
    navigateToFirstStep()
    return null
  }

  return (
    <CheckoutContainer
      reservationDetails={
        <ReservationDetailCard
          guestCount={partySize}
          oldMaxGuest={actual?.maxGuests}
          reservationDuration={includeEndTime ? selectedTimeSlot.duration : undefined}
          oldReservationDuration={includeEndTime ? actual?.duration : undefined}
          reservationTime={selectedTimeSlot?.timeIso}
          oldReservationTime={actual?.arrivalTime}
          reservationDate={selectedTimeSlot?.timeIso}
          oldReservationDate={actual?.date}
          timeSlotDescription={selectedTimeSlot?.publicTimeSlotDescription}
          oldTimeSlotDescription={actual?.timeSlotDescription}
          imageUrl={`/.h/download/${widgetSettings.headerImgPhotoDict.mega || widgetSettings.headerImgPhotoDict.medium}`}
          venueName={venueName}
          fallback={
            gmapsEmbedUrl ? (
              <EmbeddedMap clickTestId="venue-map" embeddedMapUrl={gmapsEmbedUrl} height="240px" width="100%" onMapClick={onMapClick} />
            ) : (
              <></>
            )
          }
        />
      }
    >
      <Form {...form} onSubmit={handleOnSubmit} onInvalid={handleOnInvalid} autoComplete="on">
        <VStack spacing="m">
          <ReservationCheckoutGuestLayout
            p="lm"
            socialMediaSection={
              !!provider || isModifyRoute || instantExperiencesEnabled ? null : (
                <GuestLoginPanel
                  googleApiClientId={widgetSettings.googleApiClientId}
                  selectedLanguage={selectedLanguage}
                  onFacebookClick={onLoginFB}
                  onGoogleLogin={onLoginGoogle}
                  disabled={isSubmitting}
                  socialMediaLoginEnabled={widgetSettings.enableSocialMediaLogin && !clientToken}
                />
              )
            }
            spacing="m"
          >
            <VStack spacing="m">
              <UserContactDetails field={field} disabled={isSubmitting || isModifyRoute} />
              {birthdayType !== 'Hidden' && (
                <BirthdayForm
                  required={birthdayType === 'Required'}
                  disabled={isSubmitting}
                  dayField={field.prop('birthdayDay')}
                  monthField={field.prop('birthdayMonth')}
                />
              )}
              {postalCodeType !== 'Hidden' && (
                <PostalCodeForm
                  required={postalCodeType === 'Required'}
                  postalCodeField={field.prop('postalCode')}
                  disabled={isSubmitting || lockedFields.postalCode}
                />
              )}
              {showLangPicker && (
                <PreferredLanguageForm languageField={field.prop('preferredLanguage')} options={languageOptions} disabled={isSubmitting} />
              )}
              {shouldShowBillingAddress && (
                <Label primary={`${formatMessage(reservationWidgetMessages.billingAddressLabel)}*`}>
                  <FormGMapsAddressInput
                    aria-required="true"
                    data-test="sr-reservation-billing-address"
                    disabled={isSubmitting}
                    field={field.prop('billingAddress')}
                    fullWidth
                    placeholder={formatMessage(reservationWidgetMessages.billingAddressEnterYourAddressLabel)}
                    googlePlacesApiKey={widgetSettings.googlePlacesApiKey}
                  />
                </Label>
              )}
            </VStack>
          </ReservationCheckoutGuestLayout>
          {isPaymentRequired && (
            <CardSection>
              <ErrorBoundary fallback={<></>} onError={onPaymentComponentError}>
                <CheckoutProvider
                  value={{
                    amount: dueNowAmount,
                    isSubmitting,
                    setIsSubmitting,
                    venueId,
                    googlePlacesApiKey: widgetSettings.googlePlacesApiKey,
                    resWidgetPaymentLabel: formatMessage(reservationWidgetMessages.resWidgetPaymentLabel),
                    paymentField: shouldShowCustomPaymentForm ? (field.prop('payment') as Field<PaymentCardForm>) : undefined,
                  }}
                >
                  <PaymentCheckoutComponent />
                </CheckoutProvider>
              </ErrorBoundary>
            </CardSection>
          )}
          {hasSubtotal && (
            <>
              {widgetSettings.enablePromoCodes && (
                <PromoCodeCard field={field.prop('promoCode')} dateTimeIso={selectedTimeSlot?.timeIso ?? ''} disabled={isSubmitting} />
              )}
              {showGiftCard && (
                <RedemptionGiftCard
                  field={field.prop('redemptionData')}
                  onChange={onRedemptionFieldChange}
                  totalAmount={totalAmount}
                  disabled={isSubmitting}
                />
              )}
              <ReservationCheckoutOrderSummary
                seatingAreaDescription={selectedTimeSlot?.publicTimeSlotDescription}
                guestCount={partySize}
                baseAmount={baseAmount}
                gratuityAmount={gratuityAmount}
                gratuityPercentageField={field.prop('gratuityPercentage')}
                onGratuityPercentageChange={onGratuityPercentageChange}
                hasSelectableGratuity={hasSelectableGratuity}
                selectedGratuityAmount={selectedGratuityAmount}
                promoCodeDiscountAmount={promoCodeDiscountAmount}
                promoCodeDisplayName={promoCodeName}
                serviceChargeAmount={serviceChargeAmount}
                subtotalAmount={subtotalAmount}
                taxAmount={taxAmount}
                upgradesAmounts={upgradesAmounts}
                venueCurrencyCode={currencyCode}
                disabled={isSubmitting}
                giftCardDiscountAmount={giftCardDiscountAmount}
                dueNowAmount={dueNowAmount}
              />
            </>
          )}
          {selectedExperience && (
            <PrivateEventsSummary experience={selectedExperience} currencyCode={currencyCode} timeIso={selectedTimeSlot.timeIso} />
          )}
          {widgetSettings.recaptchaOn && (
            <FormReCaptcha
              ref={recaptchaRef}
              field={field.prop('reCaptcha')}
              siteKey={widgetSettings.recaptchaSiteKey}
              locale={selectedLanguage}
            />
          )}
          <Box mt="m" mb="m">
            <AgreementTerms agreements={agreementTerms} disabled={isSubmitting} />
          </Box>
          <VStack spacing="m">
            <SubmitButton variant="primary" data-test="checkout-button-complete" fullWidth isSubmitting={isSubmitting}>
              {submitBtnLabel}
            </SubmitButton>
            <BookingPolicy
              policyHolder={widgetSettings.termsPolicyHolderName}
              termsOfServiceLink={widgetSettings.termsOfServiceUrl}
              privacyPolicyLink={widgetSettings.textCustomPrivacyPolicyLink}
              privacyPolicyHolder={widgetSettings.textCustomPolicyHolderName}
              gdprPolicyLink={widgetSettings.textCustomGdprPolicyLink}
              showSmsPolicy={agreedToReservationSmsOptInRT || agreedToVenueSmsMarketingOptInRT}
            />
          </VStack>
        </VStack>
      </Form>
      <WidgetPortal portalId="checkout-breadcrumbs">
        <ReservationBreadcrumb
          venueName={venueName}
          partySize={partySize}
          timeIso={selectedTimeSlot?.timeIso}
          holdEndTime={reservationHoldData?.holdEndTime}
          onClick={navigatePrevious}
          navigationDisabled={isSubmitting}
          label={isModifyRoute ? formatMessage(reservationWidgetMessages.resWidgetNewReservationDetailsLabel) : undefined}
        />
      </WidgetPortal>
    </CheckoutContainer>
  )
}
