import { useMemo, type ReactNode } from 'react'
import mergeRefs from 'react-merge-refs'
import sanitizeHtml from 'sanitize-html'
import { FormatService, useLocales } from '@sevenrooms/core/locales'
import { DateOnly, TimeOnly } from '@sevenrooms/core/timepiece'
import { HTMLContent } from '@sevenrooms/core/ui-kit/core'
import { Button } from '@sevenrooms/core/ui-kit/form'
import { useOverflowed, useThrottledResizeObserver } from '@sevenrooms/core/ui-kit/hooks'
import { Icon, type IconProps } from '@sevenrooms/core/ui-kit/icons'
import { DividerLine, VStack, Modal, ModalBody, ModalFooter, ModalHeader, ModalTitle, HStack, Box } from '@sevenrooms/core/ui-kit/layout'
import { Link, List, Text, TruncatingText } from '@sevenrooms/core/ui-kit/typography'
import { reservationWidgetMessages } from '../../../reservationWidgetMessages'
import { getMinSpendRange, getMinSpendValueForSelectedTime } from '../../../utils'
import { ImagesCarousel } from '../ImagesCarousel'
import type { AvailabilityTimeWithUpSellCost, PrivateEventsExperience } from '../../../hooks/useAvailability'

const IMAGE_HEIGHT = '270px'
const DEFAULT_VENUE_DESCRIPTION_LINE_COUNT = 5
const ALLOWED_DESCRIPTION_TAGS = ['div', 'p', 'span', 'br', 'u', 'b', 'strong', 'i', 'em', 'a', 'ol', 'ul', 'li']

export interface ExperienceDetailsModalProps {
  experience: PrivateEventsExperience
  timeSlot?: AvailabilityTimeWithUpSellCost
  currencyCode: string
  onClose?: () => void
  onConfirmSelect?: (timeSlot: AvailabilityTimeWithUpSellCost) => void
}

export function ExperienceDetailsModal({ experience, timeSlot, currencyCode, onClose, onConfirmSelect }: ExperienceDetailsModalProps) {
  const { formatMessage } = useLocales()
  const {
    name: label,
    description,
    heroImage,
    imageList,
    seatedCapacityMin,
    seatedCapacityMax,
    standingCapacityMin,
    standingCapacityMax,
    menu,
    roomType,
    amenities,
    squareFootage,
    squareFootageMeasurement,
    fees,
  } = experience
  const imagesToDisplay = heroImage ? [heroImage, ...imageList] : imageList
  const { timeIso, publicLongFormDescription: timeSlotDescription } = timeSlot || {}

  const { ref: resizeRef, height: wrapperHeight } = useThrottledResizeObserver(50, 'border-box')
  const [isOverflowed, overflowRef] = useOverflowed(wrapperHeight)
  const showTopDividerLine = isOverflowed || !label
  const showCapacitySeating = seatedCapacityMax && seatedCapacityMin
  const showCapacityStanding = standingCapacityMax && standingCapacityMin
  const timeOnly = TimeOnly.fromSafe(timeIso)
  const dateOnly = DateOnly.fromSafe(timeIso)
  const formattedTime = timeOnly?.formatSTime()
  const formattedDate = dateOnly?.formatSMonthNDaySWeek()
  const descriptionMessageEmpty = !sanitizeHtml(description, { allowedTags: [] }).trim()
  const timeSlotDescriptionMessageEmpty = timeSlotDescription ? !sanitizeHtml(timeSlotDescription, { allowedTags: [] }).trim() : true

  const minSpend = useMemo(() => {
    if (timeIso !== undefined) {
      const minSpend = getMinSpendValueForSelectedTime(experience, timeIso)
      return FormatService.formatCurrency(minSpend, currencyCode)
    }

    const [minSpend, maxSpend] = getMinSpendRange(experience)
    if (minSpend !== undefined) {
      return `${FormatService.formatCurrency(minSpend, currencyCode)}${
        maxSpend !== undefined && maxSpend !== minSpend ? `-${FormatService.formatCurrency(maxSpend, currencyCode)}` : ''
      }`
    }

    return undefined
  }, [timeIso, currencyCode, experience])

  return (
    <Modal ariaLabel="Modal" width="100%" maxHeight="90%" data-test="sr-experience-details-modal">
      <ModalHeader position="relative" boxShadow={isOverflowed ? 'tertiary' : 'none'} onClose={() => onClose?.()}>
        <ModalTitle title={label} />
      </ModalHeader>
      {showTopDividerLine && <DividerLine margin="none" />}
      <ModalBody ref={mergeRefs([overflowRef, resizeRef])}>
        <VStack mt={showTopDividerLine ? 'lm' : 'none'} mb={isOverflowed ? 'lm' : 'none'} spacing="l">
          {!!imagesToDisplay.length && <ImagesCarousel images={imagesToDisplay} height={IMAGE_HEIGHT} />}
          {(!!timeIso || !timeSlotDescriptionMessageEmpty) && (
            <VStack>
              {!!timeIso && (
                <VStack spacing="m">
                  <Text textStyle="h3">
                    <HStack spacing="s" flexWrap="wrap" alignItems="center">
                      <Box data-test="timeslot-date">{formattedDate}</Box>
                      <Box>•</Box>
                      <Box data-test="timeslot-time">{formattedTime}</Box>
                    </HStack>
                  </Text>
                  {!timeSlotDescriptionMessageEmpty && (
                    <TruncatingText
                      textStyle="body1"
                      whiteSpace="break-spaces"
                      spacing="xs"
                      textOverflow={DEFAULT_VENUE_DESCRIPTION_LINE_COUNT}
                      hasBlockHtmlElements
                      message={
                        <HTMLContent
                          content={timeSlotDescription || ''}
                          allowedTags={ALLOWED_DESCRIPTION_TAGS}
                          data-test="timeslot-description"
                        />
                      }
                      viewLessButtonText={formatMessage(reservationWidgetMessages.resConfirmPageViewLess)}
                      viewMoreButtonText={formatMessage(reservationWidgetMessages.resConfirmPageViewMore)}
                    />
                  )}
                  <DividerLine margin="none" mt="m" />
                </VStack>
              )}
            </VStack>
          )}
          <VStack spacing="lm">
            {!descriptionMessageEmpty && (
              <VStack spacing="m">
                <Text textStyle="h3">{formatMessage(reservationWidgetMessages.resWidgetGroupBookingsMoreDetailsDescriptionLabel)}</Text>
                <TruncatingText
                  textStyle="body1"
                  whiteSpace="break-spaces"
                  spacing="xs"
                  textOverflow={DEFAULT_VENUE_DESCRIPTION_LINE_COUNT}
                  hasBlockHtmlElements
                  message={<HTMLContent content={description} allowedTags={ALLOWED_DESCRIPTION_TAGS} data-test="room-description" />}
                  viewLessButtonText={formatMessage(reservationWidgetMessages.resConfirmPageViewLess)}
                  viewMoreButtonText={formatMessage(reservationWidgetMessages.resConfirmPageViewMore)}
                />
              </VStack>
            )}
            {roomType && <MoreDetailsModalGroup iconName="GX-visibility" label={roomType} data-test="room-type" />}
            {(showCapacitySeating || showCapacityStanding) && (
              <MoreDetailsModalGroup
                iconName="GX-groups"
                label={formatMessage(reservationWidgetMessages.resWidgetGroupBookingsMoreDetailsCapacityLabel)}
                content={
                  <>
                    {showCapacitySeating && (
                      <Text textStyle="body1" data-test="room-seating-capacity">
                        {formatMessage(reservationWidgetMessages.resWidgetGroupBookingsMoreDetailsCapacitySeatingLabel, {
                          count: `${seatedCapacityMin}-${seatedCapacityMax} ${formatMessage(
                            reservationWidgetMessages.resWidgetGuestsLabel
                          )}`,
                        })}
                      </Text>
                    )}
                    {showCapacityStanding && (
                      <Text textStyle="body1" data-test="room-standing-capacity">
                        {formatMessage(reservationWidgetMessages.resWidgetGroupBookingsMoreDetailsCapacityStandingLabel, {
                          count: `${standingCapacityMin}-${standingCapacityMax} ${formatMessage(
                            reservationWidgetMessages.resWidgetGuestsLabel
                          )}`,
                        })}
                      </Text>
                    )}
                  </>
                }
              />
            )}
            {menu && (
              <MoreDetailsModalGroup
                iconName="GX-restaurant"
                label={formatMessage(reservationWidgetMessages.resWidgetGroupBookingsMoreDetailsMenuLabel)}
                content={
                  <Text textOverflow="ellipsis">
                    <Link isExternal to={menu.url} data-test="room-card-menu-link" color="primaryFont" textDecoration="underline">
                      {menu.name}
                    </Link>
                  </Text>
                }
              />
            )}
            {(minSpend !== undefined || !!fees?.length) && (
              <MoreDetailsModalGroup
                iconName="GX-payments"
                label={formatMessage(reservationWidgetMessages.resWidgetGroupBookingsMoreDetailsPaymentsLabel)}
                content={
                  <VStack spacing="sm">
                    {minSpend !== undefined && (
                      <VStack spacing="xs">
                        <List variant="unordered" spacing="xs">
                          <Text type="li" textStyle="body1" data-test="room-min-spend-amount">
                            {formatMessage(reservationWidgetMessages.resWidgetGroupBookingsMoreDetailsMinimumSpendLabel, {
                              amount: minSpend,
                            })}
                          </Text>
                        </List>
                      </VStack>
                    )}
                    <VStack spacing="xs">
                      {!!fees?.length && (
                        <>
                          <Text textStyle="body1">
                            {formatMessage(reservationWidgetMessages.resWidgetGroupBookingsMoreDetailsOtherFeesLabel)}
                          </Text>
                          <List variant="unordered" spacing="xs">
                            {fees?.map(fee => (
                              <Text type="li" key={fee.id} textStyle="body1" data-test={`room-fee-${fee.id}`}>
                                {`${fee.label} - ${fee.value}%`}
                              </Text>
                            ))}
                          </List>
                        </>
                      )}
                      <Box ml="m">
                        <Text color="secondaryFont" fontStyle="italic" data-test="room-min-spend-amount-info">
                          {formatMessage(reservationWidgetMessages.resWidgetGroupBookingsMoreDetailsMinimumSpendInfoLabel)}
                        </Text>
                      </Box>
                    </VStack>
                  </VStack>
                }
              />
            )}
            {amenities && (
              <MoreDetailsModalGroup
                iconName="GX-tv"
                label={formatMessage(reservationWidgetMessages.resWidgetGroupBookingsMoreDetailsSpaceAmenitiesLabel)}
                content={
                  <List variant="unordered" spacing="xs">
                    {amenities.map(item => (
                      <Text type="li" key={item} textStyle="body1" data-test={`room-space-amenity-${item}`}>
                        {item}
                      </Text>
                    ))}
                  </List>
                }
              />
            )}
            {squareFootage && (
              <MoreDetailsModalGroup
                iconName="GX-size"
                label={formatMessage(reservationWidgetMessages.resWidgetGroupBookingsMoreDetailsSquareFootageLabel)}
                content={
                  <Text textStyle="body1" data-test="room-size">
                    {`${squareFootage} ${squareFootageMeasurement || ''}`}
                  </Text>
                }
              />
            )}
          </VStack>
        </VStack>
      </ModalBody>
      {isOverflowed && <DividerLine margin="none" />}
      <ModalFooter>
        {timeSlot ? (
          <Button fullWidth variant="primary" onClick={() => onConfirmSelect?.(timeSlot)} data-test="select-button" size="l">
            {formatMessage(reservationWidgetMessages.resWidgetSelectButtonLabel)}
          </Button>
        ) : (
          <Button fullWidth variant="primary" onClick={() => onClose?.()} data-test="back-button" size="l">
            {formatMessage(reservationWidgetMessages.resWidgetGroupBookingsMoreDetailsBackLabel)}
          </Button>
        )}
      </ModalFooter>
    </Modal>
  )
}

interface MoreDetailsModalGroupProps {
  iconName: IconProps['name']
  label: string
  content?: ReactNode
  'data-test'?: string
}

export function MoreDetailsModalGroup({ iconName, label, content, 'data-test': dataTest }: MoreDetailsModalGroupProps) {
  return (
    <VStack spacing="xs" data-test={dataTest}>
      <HStack spacing="s" alignItems="center">
        <Box width="24px">
          <Icon name={iconName} size="lg" />
        </Box>
        <Text textStyle="h3">{label}</Text>
      </HStack>
      {content && <VStack ml="l">{content}</VStack>}
    </VStack>
  )
}
